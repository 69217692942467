import React from 'react';
import Components from './Components/Components';
import './App.css';

function App() {
  return (
    <Components />
  );
}

export default App;
